jQuery(window).on("load", function () {
    $('#navbar').css('opacity', '1');
    $('form').css('opacity', '1');
    
    var link = document.location.href;
    var pages = 'https://digifcy.com/';
    if (link == pages) {
        setTimeout("scroll(0, 0)", 300);
    }

});

$(document).ready(function () {

    $("#menu").mmenu({
        scrollBugFix: true,
        "extensions": [
            "position-right",
            "pagedim-black",
            "border-offset",
            "shadow-page"
         ]
    });

    $("#navbar").mhead({
        // options
    });

    $("nav .mm-listitem__text").click(
        function (evnt) {
            evnt.preventDefault();
            api.close();
        }
    )

    var api = $('#menu').data('mmenu');
    api.bind("close:finish", function () {
        $('.ham').removeClass('active');
        $('.wrapper-menu').removeClass('open');

    });

    $(".form-input input").focus(function () {
        $(this).parent().find('label').addClass('focus');
    });

    $(".form-input input").blur(function () {
        if ($(this).val() == "") {
            $(this).parent().find('label').removeClass('focus');
        }
    });

    $(".tel.mm-listitem a, .icon.mm-listitem a").removeClass('mm-listitem__text');

    $(".tel.mm-listitem a, .icon.mm-listitem a").click(function () {
        var url = $(this).attr('data-url');
        window.open(url);
    });


    $("header nav a, .mm-listitem__text, .btn-anchor a, .about .btn-hover, .go-form a, .projects h3 a").click(function () {
        $('.contact-form').find('label').first().addClass('focus');
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });




    $("footer nav a").click(function () {

        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top - 84 + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });

    $('.dropdown-item').click(function () {
        var status = $(this).attr('data-status');
        $(this).parent().parent().find('.dropdown-toggle').attr("value", status).val();
        $('.dropdown-item').removeClass('active');
        $(this).addClass('active');
    })

    /*var heigthFirstScreen = $('.main').height();
    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > heigthFirstScreen) {
            $('header nav').addClass('fixed');
        } else if (st > 100) {
            $('header nav').addClass('top');
        } else {
            $('header nav').removeClass('top');
        }
        if ($(window).scrollTop() == 0) {
            $('header nav').removeClass('fixed');
        }

        lastScrollTop = st;
    });*/

    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            $('header nav').removeClass('fixed');
            $('header nav').addClass('fixed1');
        } else if (st == lastScrollTop) {
            $('header nav').removeClass('fixed1');
        } else {
            $('header nav').addClass('fixed');
        }
        if ($(window).scrollTop() == 0) {
            $('header nav').removeClass('fixed');
            $('header nav').removeClass('fixed1');
        }

        lastScrollTop = st;
    });

    if ($(window).width() < 992) {
        var lastScrollTop = 0;
        $(window).scroll(function (event) {
            var st = $(this).scrollTop();
            if (st > lastScrollTop) {
                $('#navbar').removeClass('fixed');
                $('#navbar').addClass('fixed1');
            } else if (st == lastScrollTop) {
                $('#navbar').removeClass('fixed1');
            } else {
                $('#navbar').addClass('fixed');
            }
            if ($(window).scrollTop() == 0) {
                $('#navbar').removeClass('fixed');
                $('#navbar').removeClass('fixed1');
                $('#navbar').addClass('mh-scrolledout');
            }

            lastScrollTop = st;
        });
    } else {}

    $('.main-form select, .contact-form select').selectToAutocomplete();
    $('.main-form fieldset').append($('#ui-id-1'));
    $('.contact-form fieldset .col-lg-6').append($('#ui-id-2'));



    $('.contact-form').validate({
        submitHandler: function (form) {
            /*$(form).ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.thanks').slideDown();
                    setTimeout(function () {
                        $('.thanks').slideUp();
                    }, 4000)
                }
            });*/

            $(form).ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.thanks').slideDown();
                    setTimeout(function () {
                        $('.thanks').slideUp();
                        $('.contact-form label').removeClass('focus');
                    }, 4000)
                }
            });
        }
    });

    $('.main-form').validate({
        submitHandler: function (form) {
            /*$('.main-form').ajaxForm(function () {                
                $('.thanks').slideDown();
                setTimeout(function () {
                    $('.thanks').slideUp();
                }, 4000)
            });*/

            $(form).ajaxSubmit({
                clearForm: true,
                success: function () {
                    $('.thanks').slideDown();
                    setTimeout(function () {
                        $('.thanks').slideUp();
                        $('.main-form label').removeClass('focus');
                    }, 4000)
                }
            });
        }
    });





});
